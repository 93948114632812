<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Fatture Emesse Da Incassare"
    :route-father="routeFather"
  >
   <template v-slot:header-actions>
      <div
        style="display: flex; flex-wrap: wrap; justify-content: flex-end;"
      > 
        <v-btn text link @click="goToNewCreditNote" :disabled="isLicenseBlocked">
          <v-icon class="mr-2">mdi-plus</v-icon> Nuova nota a credito
        </v-btn>
        <v-btn text link @click="goToNew" :disabled="isLicenseBlocked">
          <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
        </v-btn>
        <ExportExcelTable
          :worksheetName="worksheetName"
          :fileName="fileName"
          :fieldsToExport="fieldsToExport"
          :fetch="fetchExcel"
        > </ExportExcelTable>

        <v-btn
          icon
          @click="toggleBarcode"
          :color="!!canViewGhost ? 'red' : ''"
        >
          <v-icon>mdi-shield-account-variant</v-icon>
        </v-btn>
      </div>
    </template>
    <template v-slot:content>
      <div class="d-flex justify-center align-center mb-3" style="width: 100%;">
        <AdvancedFilter
          style="width:99%; height: 100%;"
          v-model="filtersValue"
          :filters="filterTypes"
          :advance-filters="advanceFilters"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-name="{filter}">
            Nome: {{ filter.value}}
          </template>

          <template v-slot:chip-custom-type="{filter}">
            Tipo: {{ filter.value.map(el => el.name).join(', ')}}
          </template>

          <template v-slot:custom-archived="{filter}">
            <v-row class="pa-2 px-6">
              <v-col>
                <v-switch
                  v-model="filter.value"
                  :label="filter.label"
                ></v-switch>
              </v-col>
            </v-row>
          </template>
          <template v-slot:chip-custom-archived="{filter}">
              {{ filter.value ? 'Archiviate' : 'Non Archiviate' }}
          </template>

          <template v-slot:custom-status="{filter}">
            <v-row class="pa-6">
              <v-col>
                <v-select
                  v-model="filter.value"
                  :items="[
                            //{ type: 'paid', name: 'Pagata' },
                            { type: 'cashed', name: 'Incassata' },
                            { type: 'pending', name: 'Fattura da incassare' },
                            //{ type: 'beingPaid', name: 'Pagamento a rate'}
                          ]"
                  :label="filter.label"
                  :multiple="true"
                  item-text="name"
                  item-value="type"
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </template>

          <template v-slot:custom-type="{filter}">
            <v-row class="pa-6">
              <v-col>
                <v-select
                  v-model="filter.value"
                  :items="[
                      { type: 'warehouse', name: 'Automatica magazzino' },
                      { type: 'creditNote', name: 'Nota a Credito da Incassare' },
                      { type: 'creditNoteToPay', name: 'Nota a Credito da Pagare' },
                      { type: 'other', name: 'Altro' },
                      { type: 'cashDesk', name: 'Automatica in cassa' },
                      { type: 'otherToCash', name: 'Manuale' }
                  ]"
                  :label="filter.label"
                  return-object
                  :multiple="true"
                  item-text="name"
                  item-value="type"
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </template>

          <template v-slot:custom-operator="{filter}">
            <v-row class="pb-5 pl-5">
              <v-col>
                <OperatorsAutocomplete
                  v-model="filter.value"
                  :multiple="false"
                  :filled="false"
                  dense
                  label="Operatore"
                >
                </OperatorsAutocomplete>
              </v-col>
            </v-row>
          </template>
          
          <template v-slot:custom-emittedTo="{filter}">
            <v-row class="pa-4 pl-6">
              <v-col>
                <v-text-field
                  v-model="filter.value"
                  :label="filter.label"
                  :filled="false"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </AdvancedFilter>
      </div>
      <div style="height: 83%;" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selected"
          :show-select="false"
          loading-text="Caricamento invoices ..."
          :headers="headers"
          :loading="loading"
          :items="filteredInvoices"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          :translator="translator"
          :deletable="false"
          @edit="handleEdit"
          @update:page="fetchInvoices"
          @update:row-per-page="fetchInvoices"
          @dblclick:row="handleEditDoubleClick"
        >
          <template v-slot:custom-emittedTo="{ item }">
            {{ item.lastname }}, {{ item.address }} (P.IVA {{ item.vatNumber }})
          </template>
          <template v-slot:custom-customer="{ item }">
            {{ item.firstname }} {{ item.lastname }}
          </template>
          <template v-slot:custom-amount="{ item }">
            <v-chip :color="!!item.ghost ? 'error' : 'primary'">
              {{ item["amount"] ? Number(item["amount"]).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) : (0).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }}</v-chip
            >
          </template>

          <template v-slot:custom-status="{ item }">
            <v-chip v-if="item.status === 'cencelled'" color="red lighten-3">
              {{ statusMapper[item.status] }}
            </v-chip>

            <v-chip
              v-else-if="item.status === 'pending' && item.type === 'warehouse'"
              color="red lighten-3"
            >
              {{ statusMapper[item.status + "_" + item.type] }}
            </v-chip>
            <v-chip
              v-else-if="item.status === 'pending'"
              color="blue lighten-3"
            >
              {{ statusMapper[item.status] }}
            </v-chip>

            <v-chip
              v-else-if="item.status === 'beingPaid'"
              color="orange lighten-3"
            >
              {{ statusMapper[item.status] }}
            </v-chip>

            <v-chip
              v-else-if="item.status === 'paid' && item.type === 'warehouse'"
              color="red lighten-4"
            >
              {{ statusMapper[item.status + "_" + item.type] }}
            </v-chip>
            <v-chip
              v-else-if="item.status === 'cashed' && item.type === 'warehouse'"
              color="red lighten-6"
            >
              {{ statusMapper[item.status + "_" + item.type] }}
            </v-chip>

            <v-chip v-else-if="item.status === 'paid'" color="green lighten-4">
              {{ statusMapper[[item.status]] }}
            </v-chip>
            <v-chip
              v-else-if="item.status === 'cashed'"
              color="green lighten-6"
            >
              {{ statusMapper[[item.status]] }}
            </v-chip>
          </template>

          <template v-slot:custom-paymentCredits="{ item }">
            {{ item.paymentCredits ? item.paymentCredits.length : 0 }}
          </template>
          
          <template v-slot:custom-remainingPaymentCredits="{ item }">
            {{ 
              item.paymentCredits.filter(el => el.status == 'beginning').reduce((acc, cur) => {
                return acc + Number(cur.amount)
              }, 0).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
            }}
          </template>

          <template v-slot:custom-type="{ item }">
            <v-chip color="grey lighten-3">
              {{ typeMapper[item.type] }}
            </v-chip>
          </template>

          <template v-slot:actions="{ item }">
            <v-tooltip allow-overflow bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.status !== 'paid' && item.status !== 'cashed'"
                  v-on="on"
                  v-bind="attrs"
                  icon
                  @click="handleEdit(item)"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Modifica fattura</span>
            </v-tooltip>

            <v-tooltip allow-overflow bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  v-if="item.status === 'paid' || item.status === 'cashed'"
                  icon
                  @click="handleDetail(item)"
                >
                  <v-icon small>mdi-receipt</v-icon>
                </v-btn>
              </template>
              <span>Dettaglio Fattura</span>
            </v-tooltip>

            <v-tooltip allow-overflow bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  @click="handleXMLExport(item)"
                  v-if="['paid', 'cashed'].includes(item.status)"
                >
                  <v-icon small>mdi-xml</v-icon>
                </v-btn>
              </template>
              <span>Stampa XML</span>
            </v-tooltip>

            <v-tooltip allow-overflow bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  @click="handleArchive(item)"
                  v-if="['paid', 'cashed'].includes(item.status)"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>Archivia Fattura</span>
            </v-tooltip>
          </template>

          <template v-slot:externalFooter>
            <TypeDataTable
              :headers="totalHeaders"
              :items="totalRow"
              :show-select="false"
              :show-actions="false"
              disablePagination
            >
            </TypeDataTable>
          </template>
        </TypeDataTable>
      </div>

      <StandardDialog
        v-model="dialogBarcode"
        title="Area Riservata Sviluppatori" 
        :dialog-height="null"
        dialog-max-width="500px"
      >
        <ManualBarcodeInput
          v-model="operatorBarcode"
          scan-your-barcode-message="Immettere Codice" 
          @confirm="activeBarcode"
        >
        </ManualBarcodeInput>
      </StandardDialog>

      <StandardDialog
        v-model="errorDialogBarcode"
        :dialog-height="null"
        :dialog-width="null"
      >
        <v-alert
          class="mt-3"
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          {{messagErrorDialog}}
        </v-alert>
      </StandardDialog>
    </template>
  </FullScreenDialog>
</template>

<script>
import Printer from "@/services/devices/invoicePrinter";

import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import { SearchBar } from "likablehair-ui-components";
import invoiceService from "@/services/invoices/invoices.service.js";
import settingService from "@/services/settings/settings.service.js";
import operatorService from '@/services/operators/operators.service.js'
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";
import StandardDialog from '@/components/common/StandardDialog.vue';
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import dateUtils from "@/mixins/common/dateUtils"

export default {
  name: "InvoicesToCashList",
  components: {
    FullScreenDialog,
    SearchBar,
    TypeDataTable,
    AdvancedFilter,
    ManualBarcodeInput,
    StandardDialog,
    OperatorsAutocomplete,
    ExportExcelTable
  },
  data: function () {
    let props = this.$router.resolve({ name: "Payments" });

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      invoices: [],
      headers: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 0,
      resizeListener: undefined,
      loadingArchive: undefined,
      invoicesListFilter: [],
      /*currentFilters: {
        toCash: true,
        excludeGhost: true
      },*/
      typeMapper: {
        warehouse: "Automatica magazzino",
        creditNote: "Nota a Credito",
        restaurant: "Manuale ristorante",
        saloon: "Manuale salone",
        other: "Altro",
        cashDesk: "Automatica in cassa",
        migration: "Automatica da migrazione",
        otherToCash: "Manuale"
      },
      statusMapper: {
        pending: "Fattura da incassare",
        pending_warehouse: "Pagamento da effettuare",
        paid_warehouse: "Pagata",
        paid_restaurant: "Incassata",
        paid_saloon: "Incassata",
        paid_cashDesk: "Incassata",
        cashed: "Incassata",
        paid: "Pagata",
        cancelled: "Cancellata",
        beingPaid: "Pagamento a rate",
      },
      dialogBarcode: false,
      operatorBarcode: undefined,
      errorDialogBarcode: false,
      messagErrorDialog: '',
      canViewGhost: false,
      filterTypes: [
        { type: 'date', operator: 'between', field: 'emittedAt', name: 'Emessa il', label: 'Il giorno', color: "", value: [undefined, undefined] },
        { type: 'date', operator: 'between', field: 'expirationDate', name: 'Data di scadenza', label: 'Il giorno', color: "", value: [undefined, undefined] },
        { type: 'string', operator: 'like', field: 'documentNumber', name: 'Numero Fattura', label: 'Numero Fattura', color: "", value: undefined },
        { type: 'number', operator: 'equal', field: 'amount', name: 'Ammontare', label: 'Ammontare', color: "", value: undefined },
        { type: 'string', operator: 'equal', field: 'vatNumber', name: 'P.IVA', label: 'P.IVA', color: "", value: undefined },
        { type: 'string', operator: 'equal', field: 'internalCode', name: 'Codice Interno', label: 'Codice Interno', color: "", value: undefined },
        { type: 'custom', operator: 'custom', field: 'status', name: 'Stato', label: 'Stato', color: "", value: [
          { type: 'paid', name: 'Pagata' },
          { type: 'cashed', name: 'Incassata' },
          { type: 'pending', name: 'Fattura da incassare' },
          { type: 'beingPaid', name: "Pagamento a rate"},
        ], icon: 'mdi-format-list-bulleted-type' }, 
        { type: 'custom', operator: 'custom', field: 'emittedTo', name: 'Intestata a', label: 'Intestata a', color: "", value: undefined, icon: 'mdi-text-search' },
        { type: 'number', operator: 'equal', field: 'paymentCreditsNumber', name: 'N° Rate', label: 'N° Rate', color: "", value: undefined },
        { type: 'cash', operator: 'equal', field: 'remainingPaymentCredits', name: 'Residuo Rate', label: 'Residuo Rate', color: "", value: undefined }
      ],
      advanceFilters: [
        { type: 'custom', operator: 'custom', field: 'type', name: 'Tipo', label: 'Tipo', color: "", value: [
          { type: 'warehouse', name: 'Automatica magazzino' },
          { type: 'creditNote', name: "Nota a Credito" },
          { type: 'creditNoteToPay', name: 'Nota a Credito da Pagare' },
          { type: 'other', name: "Altro" },
          { type: 'cashDesk', name: "Automatica in cassa" },
          { type: 'otherToCash', name: "Manuale" }
        ], icon: 'mdi-format-list-bulleted-type' },
        //{ type: 'custom', operator: 'custom', field: 'operator', name: 'Operatore', label: 'Label', color: "", icon: 'mdi-account'},
        //{ type: 'custom', operator: 'custom', field: 'paymentCredits', name: 'Con Rate', label: 'Con Rate', color: "", value: undefined, icon: 'mdi-credit-card-clock-outline' },
        { type: 'string', operator: 'like', field: 'description', name: 'Descrizione', label: 'Descrizione', color: "", value: undefined },

        { type: 'custom', operator:'custom', field: 'archived', name: 'Archiviate', label: 'Archiviate', color: "", value: false, icon: 'mdi-archive' }
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'name', name: 'Nome', label: 'Nome', color: "", value: undefined, icon: 'mdi-text-search' },
      filtersValue: [
      ],
      worksheetName: "",
      fileName:"",
      fieldsToExport: {},
      totalHeaders: [
        { text: 'N° Fatture', value: 'count' },
        { text: 'Totale', value: 'total', type: 'price' },
      ],
      totalRow: [{}],
      isLicenseBlocked: undefined
    };
  },
  mixins:[dateUtils],
  props: {
    filters: {
      type: Array,
      default: function() {
        return [
          { type: 'custom', operator:'custom', field: 'archived', name: 'Archiviate', label: 'Archiviate', color: "", value: false, icon: 'mdi-archive' }
        ]
      }
    }
  },
  mounted: function () {
    this.fetchHeaders();
    this.filtersValue = this.filters
    this.fetchInvoices();

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight *0.80;

    this.resizeListener = function () {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight *0.80;
    };
    this.resizeListener = this.resizeListener.bind(this);
    window.addEventListener("resize", this.resizeListener);

    this.fieldsToExport = invoiceService._fieldsToExport();
    this.worksheetName = "Export Fatture Emesse";
    this.fileName =
      "FattureEmesse-DaIncassare_" + this._buildDateToExport(new Date()) +
      ".xls";
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.resizeListener);
  },
  methods: {
    fetchInvoices() {
      this.loadingItems = true;
      let filters = [...this.filtersValue, 
        { type: 'custom', operator: 'custom', field: 'ghost', value: this.canViewGhost },
        { type: 'boolean', operator: 'equal', field:'activeCycle', value: true }
      ];

      invoiceService
        .list(this.page, this.rowPerPage, filters)
        .then((results) => {
          if (this.rowPerPage != results.rowPerPage) {
            this.rowPerPage = results.rowPerPage;
          }

          if (this.page != results.page) {
            this.page = results.page;
          }

          this.totalPages = results.totalPages;
          if (this.totalPages < this.page && this.totalPages != 0) {
            this.page = this.totalPages;
          }

          this.totalRow = [results.totalRow]
          this.invoices = results.rows;
          this.loadingItems = false;
        });
    },
    fetchHeaders() {
      this.loadingHeaders = true;
      invoiceService.fields().then((headers) => {
        this.headers = headers;
        this.loadingHeaders = false;
      });
    },
    async handleXMLExport(invoice) {
      let companyInvoiceDataResult = await settingService.list("company");
      let companyData = {}
      for (const [key, obj] of Object.entries(companyInvoiceDataResult)) {
        companyData[key] = obj.value;
      }
      let rtResponse = await Printer.printInvoiceXML({ currentInvoice: invoice, companyData: companyData  });
      await this.$delegates.snackbar("Download completato");
    },
    handleEdit(invoice) {
      if(invoice.type == 'creditNote') {
        this.$router.push({
          name: "InvoicesCreditNoteEditForm",
          params: {
            id: invoice.id,
            variant: "edit",
            filters: this.filtersValue
          },
          query: {
            pathToGoBack: this.$router.resolve({ name: "InvoicesToCashList" }).href,
            pathName: 'InvoicesToCashList',
          },
        });
      } else {
        this.$router.push({
          name: "InvoicesToCashEditForm",
          params: {
            id: invoice.id,
            variant: "edit",
            filters: this.filtersValue
          },
          query: {
            pathToGoBack: this.$router.resolve({ name: "InvoicesToCashList" }).href,
            pathName: 'InvoicesToCashList',
          },
        });
      }
    },
    handleEditDoubleClick(row,  {item: invoice}) {
      if(invoice.type == 'creditNote') {
        this.$router.push({
          name: "InvoicesCreditNoteEditForm",
          params: {
            id: invoice.id,
            variant: (!!invoice && invoice.status) == 'pending' ? "edit" : "detail",
            filters: this.filtersValue
          },
          query: {
            pathToGoBack: this.$router.resolve({ name: "InvoicesToCashList" }).href,
            pathName: 'InvoicesToCashList'
          },
        });
      } else {
        this.$router.push({
          name: "InvoicesToCashEditForm",
          params: {
            id: invoice.id,
            variant: (!!invoice && invoice.status) == 'pending' ? "edit" : "detail",
            filters: this.filtersValue
          },
          query: {
            pathToGoBack: this.$router.resolve({ name: "InvoicesToCashList" }).href,
            pathName: 'InvoicesToCashList'
          },
        });
      }
    },
    handleDetail(invoice) {
      if(invoice.type == 'creditNote') {
        this.$router.push({
          name: "InvoicesCreditNoteEditForm",
          params: {
            id: invoice.id,
            variant: "detail",
            filters: this.filtersValue
          },
          query: {
            pathToGoBack: this.$router.resolve({ name: "InvoicesToCashList" }).href,
            pathName: 'InvoicesToCashList'
          },
        });
      } else {
        this.$router.push({
          name: "InvoicesToCashEditForm",
          params: {
            id: invoice.id,
            variant: "detail",
            filters: this.filtersValue
          },
          query: {
            pathToGoBack: this.$router.resolve({ name: "InvoicesToCashList" }).href,
            pathName: 'InvoicesToCashList'
          },
        });
      }
    },
    goToNew() {
      this.$router.push({
        name: "InvoicesToCashNewForm",
        query: {
          pathToGoBack: this.$router.resolve({ name: "InvoicesToCashList" }).href,
        },
      });
    },
    goToNewCreditNote() {
      this.$router.push({
        name: "InvoicesCreditNoteNewForm",
        query: {
          pathToGoBack: this.$router.resolve({ name: "InvoicesToCashList" }).href,
        },
      });
    },
    translator(key, value) {
      const typeMapper = {
        cashDesk: "Fattura in cassa",
        warehouse: "Fattura da ordine",
      };
      if (key == "type") {
        return typeMapper[value];
      }
    },
    applyFilters(filters) {
      this.fetchInvoices();
    },
    handleArchive(invoice) {
      let confirmed = confirm("Sei sicuro di voler archiviare la fattura?");
      if (confirmed) {
        invoiceService.archive(invoice).then(() => {
          this.fetchInvoices();
        });
      }
    },
    toggleBarcode(){
      this.dialogBarcode = !this.dialogBarcode
    },
    activeBarcode(){
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then((result) => {
        if (result) {
          this.canViewGhost = !this.canViewGhost
          this.$delegates.snackbar('Operatore autorizzato')
          this.fetchInvoices()
        } else {
          this.messagErrorDialog = 'Operatore non autorizzato'
          this.errorDialogBarcode = true
        }
      }).catch((err) => {
        this.messagErrorDialog = 'Codice non riconosciuto'
        this.errorDialogBarcode = true
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
    async fetchExcel(){
      let filters = [...this.filtersValue, 
        { type: 'custom', operator: 'custom', field: 'ghost', value: this.canViewGhost },
        { type: 'boolean', operator: 'equal', field:'activeCycle', value: true}
      ]
      
      let invoicesToExport = await invoiceService.list(undefined, 5000, filters)
      if(!!invoicesToExport.rows)
      invoicesToExport = invoicesToExport.rows.map(i=> {
        i.fullname = (!!i.firstname ? (i.firstname + " ") : ('' +  " ")) + (!!i.lastname ? i.lastname : '')
        i.emittedTo = !!i.lastname ? i.lastname : ''
        i.emittedTo += !!i.address ? ", " + i.address : '' 
        i.emittedTo += !!i.vatNumber ? ", (P.IVA " + i.vatNumber + ")" :''
        return i 
      })

      return invoicesToExport
    }
  },
  computed: {
    filteredInvoices() {
      return this.invoices;
    },
    loading() {
      return this.loadingItems || this.loadingHeaders;
    },
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  watch: {
    page() {
      this.fetchInvoices();
    },
    rowPerPage() {
      this.fetchInvoices();
    },
    invoicesListFilter(newVal) {
      this.invoicesListFilter = newVal;
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>
<style scoped>
.border-default {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #DCD8D8;
  transition: all 0.2s;
  transform: scale(1);
}
</style>
